var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.status === "OFFLINE" || this.offline
      ? _c(
          "div",
          { class: _vm.colClass },
          [
            _c("fa-icon", {
              class: _vm.iconClass,
              style: { color: "gray" },
              attrs: { icon: "minus-circle", size: _vm.size },
            }),
            !_vm.nolabel
              ? _c("span", { class: _vm.textClass }, [_vm._v("Offline")])
              : _vm._e(),
          ],
          1
        )
      : _vm.status === "RECORDING" && !_vm.alarm
      ? _c(
          "div",
          { class: _vm.colClass },
          [
            _c("fa-icon", {
              class: _vm.iconClass,
              style: { color: "green" },
              attrs: { icon: "check-circle", size: _vm.size },
            }),
            !_vm.nolabel
              ? _c("span", { class: _vm.textClass }, [_vm._v("Recording")])
              : _vm._e(),
          ],
          1
        )
      : _vm.status === "BUSY" && !_vm.alarm
      ? _c(
          "div",
          { class: _vm.colClass },
          [
            _c("fa-icon", {
              class: _vm.iconClass,
              style: { color: "red" },
              attrs: { icon: "ellipsis-h", size: _vm.size },
            }),
            !_vm.nolabel
              ? _c("span", { class: _vm.textClass }, [_vm._v("Busy")])
              : _vm._e(),
          ],
          1
        )
      : _vm.status === "WAITING" && !_vm.alarm
      ? _c(
          "div",
          { class: _vm.colClass },
          [
            _c("fa-icon", {
              class: _vm.iconClass,
              style: { color: "orange" },
              attrs: { icon: "ellipsis-h", size: _vm.size },
            }),
            !_vm.nolabel
              ? _c("span", { class: _vm.textClass }, [_vm._v("Waiting")])
              : _vm._e(),
          ],
          1
        )
      : _vm.status === "IDLE" && !_vm.alarm
      ? _c(
          "div",
          { class: _vm.colClass },
          [
            _c("fa-icon", {
              class: _vm.iconClass,
              style: { color: "black" },
              attrs: { icon: "check-circle", size: _vm.size },
            }),
            !_vm.nolabel
              ? _c("span", { class: _vm.textClass }, [_vm._v("Idle")])
              : _vm._e(),
          ],
          1
        )
      : _vm.status === "STARTUP" && !_vm.alarm
      ? _c(
          "div",
          { class: _vm.colClass },
          [
            _c("fa-icon", {
              class: _vm.iconClass,
              style: { color: "blue" },
              attrs: { icon: "chevron-circle-up", size: _vm.size },
            }),
            !_vm.nolabel
              ? _c("span", { class: _vm.textClass }, [_vm._v("Startup")])
              : _vm._e(),
          ],
          1
        )
      : _vm.alarm
      ? _c(
          "div",
          { class: _vm.colClass, attrs: { cols: "auto" } },
          [
            _c("fa-icon", {
              class: _vm.iconClass,
              style: { color: "red" },
              attrs: { icon: "exclamation-circle", size: _vm.size },
            }),
            !_vm.nolabel
              ? _c("span", { class: _vm.textClass }, [_vm._v("Alarm")])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }